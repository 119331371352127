import styled, { css } from 'styled-components'
import Spacing from './spacing'

const size = {
    small: '425px',
    medium: '768px',
    large: '1024px',
    extra: '1440px',
}

export const BREAKPOINTS = {
    mobile: `(min-width: ${size.small})`,
    tablet: `(min-width: ${size.medium})`,
    laptop: `(min-width: ${size.large})`,
    desktop: `(min-width: ${size.extra})`,
}

export const GRIDS = {
    layout: () => css`
        grid-template-columns: min(32ch, 50%) min(32ch, 50%);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        @media ${() => `${BREAKPOINTS.mobile}`} {
            grid-template-columns: min(65ch, 50%) min(65ch, 50%);
            grid-column-gap: 0px;
        }
    `,
    content: () => css`
        grid-template-columns: [left-gutter] 1fr [left-main] 1fr [main] 8fr [half-main] 8fr [right-main] 1fr [right-gutter] 1fr;
        grid-column-gap: ${() => `${Spacing.xs}`};
        grid-row-gap: 0px;

        @media ${() => `${BREAKPOINTS.mobile}`} {
            grid-template-columns: [left-gutter] 1fr [left-main] 2fr [main] 3fr [half-main] 3fr [right-main] 2fr [right-gutter] 1fr;
            grid-column-gap: ${() => `${Spacing.xxxl}`};
        }
    `,
    cards: (props) => css`
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: ${() => `${Spacing.lg}`};
        grid-row-gap: ${() => `${Spacing.lg}`};

        @media ${() => `${BREAKPOINTS.mobile}`} {
            grid-template-columns: ${(props) => props.columnTemplate ? `${props.columnTemplate}` : `1fr 1fr 1fr`};
        }
    `,
}
