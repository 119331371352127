import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

import GlobalStyles from './shared/global-styles'
import { BREAKPOINTS, GRIDS } from './shared/grid'

import Copyright from './global/copyright'
import Help from './global/help'
import { H2, P } from './shared/typography'

export default function Layout({ children }) {
    return (
        <>
            <GlobalStyles />
            <Header>
                <Logo>
                    <GatsbyLink to="/">
                        <img
                            src="/images/logo-icon.svg"
                            alt="Western Reserve Area Agency on Aging - Assistance Directory"
                        />
                    </GatsbyLink>
                </Logo>
                <ProvidedBy>
                    <H2 size="subtitle">
                        <strong>Provided by</strong>
                    </H2>
                    <P size="caption">
                        <a href="https://www.areaagingsolutions.org">
                            Western Reserve Area Agency on Aging
                        </a>
                    </P>
                </ProvidedBy>
            </Header>
            <MainContent>{children}</MainContent>
            <Footer>
                <Help />
                <Copyright />
            </Footer>
        </>
    )
}

const Header = styled.header`
    display: grid;
    ${() => GRIDS.layout()}
    align-items: center;
    margin: 0 auto;
    padding: var(--space-m) var(--space-m) 0 var(--space-m);
    max-width: min(130ch, 100vw);

    @media ${() => `${BREAKPOINTS.tablet}`} {
        max-width: min(130ch, 80vw);
    }

    & > *:nth-child(odd) {
        grid-column: 1;
    }

    & > *:nth-child(even) {
        grid-column: 2;
    }
`

const Logo = styled.h1`
    max-width: 100px;

    img {
        max-width: 100%;
    }
`

const ProvidedBy = styled.div`
    text-align: right;
`

const MainContent = styled.main`
    display: grid;
    ${() => GRIDS.layout()}
    margin: 0 auto;
    max-width: 100vw;

    @media ${() => `${BREAKPOINTS.tablet}`} {
        max-width: min(130ch, 80vw);
    }

    & > *:nth-child(odd) {
        grid-column: 1 / -1;

        @media ${() => `${BREAKPOINTS.tablet}`} {
            grid-column: 1;
        }
    }

    & > *:nth-child(even) {
        grid-column: 1 / -1;

        @media ${() => `${BREAKPOINTS.tablet}`} {
            grid-column: 2;
        }
    }
`

const Footer = styled.footer`
    margin: var(--space-m) auto 0 auto;
    padding: var(--space-m);
    max-width: min(130ch, 100vw);

    @media ${() => `${BREAKPOINTS.tablet}`} {
        padding: 0;
        max-width: min(130ch, 80vw);
    }
`
