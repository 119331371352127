import * as React from 'react'
import styled from 'styled-components'

import Theme from '../shared/colors'

import { typestyle } from '../shared/typography'

const ProviderHours = () => {
    return (
        <Week>
            <Day><strong>Sunday</strong></Day>
            <Hours>Closed</Hours>
            <Day><strong>Monday</strong></Day>
            <Hours>8:00 AM - 5:00 PM</Hours>
            <Day><strong>Tuesday</strong></Day>
            <Hours>8:00 AM - 5:00 PM</Hours>
            <Day><strong>Wednesday</strong></Day>
            <Hours>8:00 AM - 5:00 PM</Hours>
            <Day><strong>Thursday</strong></Day>
            <Hours>8:00 AM - 5:00 PM</Hours>
            <Day><strong>Friday</strong></Day>
            <Hours>8:00 AM - 5:00 PM</Hours>
            <Day><strong>Saturday</strong></Day>
            <Hours>Closed</Hours>
        </Week>
    )
}

const Week = styled.dl`
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: var(--space-s) auto 0 auto;
    border: 1px solid ${Theme.ui.secondary};
    border-radius: var(--space-2xs);
`

const Day = styled.dt`
    margin: 0;
    padding: var(--space-2xs);
    border-bottom: 1px solid ${Theme.ui.secondary};

    ${(props) => {
        props.size = props.size ? props.size : 'caption'
        return typestyle()
    }}
`

const Hours = styled.dd`
    margin: 0;
    padding: var(--space-2xs);
    border-bottom: 1px solid ${Theme.ui.secondary};

    ${(props) => {
        props.size = props.size ? props.size : 'caption'
        return typestyle()
    }}
`

export default ProviderHours
