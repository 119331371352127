import * as React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BREAKPOINTS, GRIDS } from '../shared/grid'
import Theme from '../shared/colors'

import Button from '../content/button'
import Link from '../shared/link'
import { H2, H3, P, Span } from '../shared/typography'
import ProviderHours from '../provider/provider-hours'

const Help = () => {
    return (
        <HelpContent>
            <div>
                <H2 size='h4'>
                    <Span size="overline">
                        Aging and Disability Resource Center
                    </Span>
                    Need Help Now?
                </H2>
                <P size='h3'>
                    <Link to="tel:1-800-626-7277">1-800-626-7277</Link>
                </P>
                <P>
                    Our representatives at the Western Reserve Area Agency on
                    Aging are prepared to help connect you with trusted local
                    programs.
                </P>
            </div>
            <aside>
                <H3 size='h5'>Office Hours</H3>
                <ProviderHours />
            </aside>
        </HelpContent>
    )
}

const HelpContent = styled.section`
    display: grid;
    ${() => GRIDS.layout()}
    margin: 0 auto;
    padding: 0;
    border: 2px solid ${Theme.ui.secondary};
    border-radius: var(--space-xs);

    & > *:nth-child(odd) {
        padding: var(--space-m);
        grid-column: 1 / -1;

        @media ${() => `${BREAKPOINTS.mobile}`} {
            grid-column: 1;
        }
    }

    & > *:nth-child(even) {
        padding: var(--space-m);
        grid-column: 1 / -1;

        @media ${() => `${BREAKPOINTS.mobile}`} {
            grid-column: 2;
        }
    }
`

const AvailableIcon = styled(FontAwesomeIcon)`
    padding-right: var(--space-3xs);
`

export default Help
