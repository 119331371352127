import * as React from 'react'
import styled, { css } from 'styled-components'

import Theme from '../shared/colors'

const Button = styled.button`
    margin: var(--space-s) 0;
    padding: var(--space-3xs) var(--space-xs);
    border: 1px solid ${Theme.text.link};
    border-radius: var(--space-3xs);
    text-decoration: none;
    background: white;
    color: ${Theme.text.link};

    ${(props) =>
        props.primary &&
        css`
            background: ${Theme.text.link};
            color: white;
        `}

    &:hover {
        cursor: pointer;
    }
`

export default Button
