const colors = {
    blueViolet: '#8d50d1',
    gondola: '#343434',
    pacificBlue: '#0d85e7',
    tahitiGold: '#d46c39',
    whiteSmoke: '#EFEFEF',
    wildWillow: '#5e8b2b',
    white: 'rgba(255,255,255,1)',
}

const Theme = {
    brand: {
        primary: `${colors.tahitiGold}`,
        secondary: `${colors.pacificBlue}`,
    },
    ui: {
        primary: `${colors.gondola}`,
        secondary: `${colors.whiteSmoke}`,
        tertiary: `${colors.wildWillow}`,
        row: `${colors.whiteSmoke}`
    },
    text: {
        primary: `${colors.gondola}`,
        secondary: `${colors.tahitiGold}`,
        heading: `${colors.pacificBlue}`,
        link: `${colors.blueViolet}`,
        hover: `${colors.wildWillow}`,
        inverse: `${colors.white}`,
    },
    field: {
        background: `${colors.white}`,
        text: `${colors.tahitiGold}`,
    },
    support: `${colors.white}`,
}

export default Theme