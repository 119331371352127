import styled, { css } from 'styled-components'

import Theme from './colors'

const rem = (pixels) => {
    return `${pixels / baseFontSize}rem`
}

const baseFontSize = '16'

const lineHeight = {
    display: `1.05`,
    heading: `1.2`,
    body: `1.5`,
}

const family = {
    primary: 'Ubuntu, sans-serif',
    secondary: 'Ubuntu, sans-serif',
}

export const weights = {
    extraLight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    black: '800',
}

export const typestyle = () => css`
    ${(props) => {
        switch (props.size ? props.size : 'body') {
            case 'cover':
                return `
                    font-family: ${family.secondary};
                    font-size: var(--step-5);
                    font-weight: ${weights.regular};
                    line-height: ${lineHeight.display};
                `
            case 'display':
                return `
                    font-family: ${family.secondary};
                    font-size: var(--step-5);
                    font-weight: ${weights.regular};
                    line-height: ${lineHeight.display};
                `
            case 'h1':
                return `
                    font-family: ${family.secondary};
                    font-size: var(--step-5);
                    font-weight: ${weights.regular};
                    line-height: ${lineHeight.display};
                `
            case 'h2':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-4);
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};
                `
            case 'h3':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-3);
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};
                `
            case 'h4':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-2);
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};
                    text-transform: UPPERCASE;
                    letter-spacing: 1.5px;
                `
            case 'h5':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-1);
                    font-weight: ${weights.black};
                    line-height: ${lineHeight.heading};
                `
            case 'h6':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-0);
                    font-weight: ${weights.bold};
                    line-height: ${lineHeight.heading};
                `
            case 'body':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-0);
                    line-height: ${lineHeight.body};
                `
            case 'subtitle':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step--2);
                    line-height: ${lineHeight.body};
                `
            case 'overline':
                return `
                    display: block;
                    font-family: ${family.primary};
                    font-size: var(--step--2);
                    font-weight: ${weights.light};
                    line-height: ${lineHeight.body};
                `
            case 'button':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-0);
                    font-weight: ${weights.extraLight};
                    line-height: ${lineHeight.heading};
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                `
            case 'caption':
                return `
                    font-family: ${family.primary};
                    font-size: var(--step--1);
                    line-height: ${lineHeight.heading};
                `
            default:
                return `
                    font-family: ${family.primary};
                    font-size: var(--step-0);
                    line-height: ${lineHeight.body};
                `
        }
    }}
`

export const P = styled.p`
    margin: ${(props) => (props.margin ? props.margin : '0 0 1em 0')};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}

    &:empty {
        display: none;
    }
`

export const Span = styled.span`
    margin: ${(props) => (props.margin ? props.margin : '0')};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`

export const H1 = styled.h1`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    color: ${Theme.text.heading};

    ${(props) => {
        props.size = props.size ? props.size : 'h1'
        return typestyle()
    }}
`

export const H2 = styled.h2`
    margin: ${(props) => (props.margin ? props.margin : 0)};

    ${(props) => {
        props.size = props.size ? props.size : 'h2'
        return typestyle()
    }}
`

export const H3 = styled.h3`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'h3'
        return typestyle()
    }}
`

export const H4 = styled.h4`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'h4'
        return typestyle()
    }}
`

export const H5 = styled.h5`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'h5'
        return typestyle()
    }}
`

export const H6 = styled.h6`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'h6'
        return typestyle()
    }}
`

export const UL = styled.ul`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`

export const OL = styled.ol`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`

export const BLOCKQUOTE = styled.blockquote`
    margin: ${(props) => (props.margin ? props.margin : 0)};
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`
