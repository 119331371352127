import { createGlobalStyle } from 'styled-components'

import Theme from './colors'

// Create a `GlobalStyles` component.
// I usually already have this, to include a CSS
// reset, set border-box, and other global concerns.
const GlobalStyles = createGlobalStyle`
    /* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1280,22,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

    :root {
        --step--2: clamp(0.69rem, 0.63rem + 0.31vw, 0.88rem);
        --step--1: clamp(0.83rem, 0.74rem + 0.44vw, 1.10rem);
        --step-0: clamp(1.00rem, 0.88rem + 0.63vw, 1.38rem);
        --step-1: clamp(1.20rem, 1.03rem + 0.86vw, 1.72rem);
        --step-2: clamp(1.44rem, 1.20rem + 1.18vw, 2.15rem);
        --step-3: clamp(1.73rem, 1.41rem + 1.60vw, 2.69rem);
        --step-4: clamp(2.07rem, 1.65rem + 2.14vw, 3.36rem);
        --step-5: clamp(2.49rem, 1.92rem + 2.85vw, 4.20rem);
    }

    /* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1140,24,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6, */

    :root {
        --fluid-min-width: 320;
        --fluid-max-width: 1140;

        --fluid-screen: 100vw;
        --fluid-bp: calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) / (var(--fluid-max-width) - var(--fluid-min-width)));
    }

    @media screen and (min-width: 1140px) {
        :root {
            --fluid-screen: calc(var(--fluid-max-width) * 1px);
        }
    }

    :root {
        --fc-3xs-min: (var(--fc-s-min) * 0.25); 
        --fc-3xs-max: (var(--fc-s-max) * 0.25);

        --fc-2xs-min: (var(--fc-s-min) * 0.5); 
        --fc-2xs-max: (var(--fc-s-max) * 0.5);

        --fc-xs-min: (var(--fc-s-min) * 0.75); 
        --fc-xs-max: (var(--fc-s-max) * 0.75);

        --fc-s-min: (var(--f-0-min, 16)); 
        --fc-s-max: (var(--f-0-max, 24));

        --fc-m-min: (var(--fc-s-min) * 1.5); 
        --fc-m-max: (var(--fc-s-max) * 1.5);

        --fc-l-min: (var(--fc-s-min) * 2); 
        --fc-l-max: (var(--fc-s-max) * 2);

        --fc-xl-min: (var(--fc-s-min) * 3); 
        --fc-xl-max: (var(--fc-s-max) * 3);

        --fc-2xl-min: (var(--fc-s-min) * 4); 
        --fc-2xl-max: (var(--fc-s-max) * 4);

        --fc-3xl-min: (var(--fc-s-min) * 6); 
        --fc-3xl-max: (var(--fc-s-max) * 6);

        /* T-shirt sizes */
        --space-3xs: calc(((var(--fc-3xs-min) / 16) * 1rem) + (var(--fc-3xs-max) - var(--fc-3xs-min)) * var(--fluid-bp));
        --space-2xs: calc(((var(--fc-2xs-min) / 16) * 1rem) + (var(--fc-2xs-max) - var(--fc-2xs-min)) * var(--fluid-bp));
        --space-xs: calc(((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-xs-max) - var(--fc-xs-min)) * var(--fluid-bp));
        --space-s: calc(((var(--fc-s-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-s-min)) * var(--fluid-bp));
        --space-m: calc(((var(--fc-m-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-m-min)) * var(--fluid-bp));
        --space-l: calc(((var(--fc-l-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-l-min)) * var(--fluid-bp));
        --space-xl: calc(((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-xl-min)) * var(--fluid-bp));
        --space-2xl: calc(((var(--fc-2xl-min) / 16) * 1rem) + (var(--fc-2xl-max) - var(--fc-2xl-min)) * var(--fluid-bp));
        --space-3xl: calc(((var(--fc-3xl-min) / 16) * 1rem) + (var(--fc-3xl-max) - var(--fc-3xl-min)) * var(--fluid-bp));
        
        /* One-up pairs */
        --space-3xs-2xs: calc(((var(--fc-3xs-min) / 16) * 1rem) + (var(--fc-2xs-max) - var(--fc-3xs-min)) * var(--fluid-bp));
        --space-2xs-xs: calc(((var(--fc-2xs-min) / 16) * 1rem) + (var(--fc-xs-max) - var(--fc-2xs-min)) * var(--fluid-bp));
        --space-xs-s: calc(((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-xs-min)) * var(--fluid-bp));
        --space-s-m: calc(((var(--fc-s-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-s-min)) * var(--fluid-bp));
        --space-m-l: calc(((var(--fc-m-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-m-min)) * var(--fluid-bp));
        --space-l-xl: calc(((var(--fc-l-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-l-min)) * var(--fluid-bp));
        --space-xl-2xl: calc(((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-2xl-max) - var(--fc-xl-min)) * var(--fluid-bp));
        --space-2xl-3xl: calc(((var(--fc-2xl-min) / 16) * 1rem) + (var(--fc-3xl-max) - var(--fc-2xl-min)) * var(--fluid-bp));

        /* Custom pairs */
    }

    body {
        margin: 0;
        padding: 0;
        font-size: var(--step-0);
        font-weight: 300;
        color: ${Theme.text.primary};

        * {
            box-sizing: border-box;
        }
    }
`

export default GlobalStyles
