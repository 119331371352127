import * as React from 'react'
import styled from 'styled-components'

import { P } from '../shared/typography'

const Copyright = () => {
    return (
        <CopyrightContent size='subtitle'>&copy; Western Reserve Area Agency on Aging. All rights reserved.</CopyrightContent>
    )
}


const CopyrightContent = styled(P)`
    padding: var(--space-m);
    text-align: right;
`

export default Copyright