import React, { useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled, { css } from 'styled-components'

import Theme from './colors'
import { BREAKPOINTS } from './grid'
import Spacing from './spacing'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, button, ...other }) => {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)
    const phone = to.includes('tel:')

    if (button) {
        // Use Gatsby Link for internal links, and <a> for others
        if (internal && !phone) {
            return (
                <InternalButton
                    to={to}
                    activeClassName={activeClassName}
                    partiallyActive={partiallyActive}
                    {...other}
                >
                    {children}
                </InternalButton>
            )
        }
        return (
            <Button href={to} {...other}>
                {children}
            </Button>
        )
    }

    // Use Gatsby Link for internal links, and <a> for others
    if (internal && !phone) {
        return (
            <InternalLink
                to={to}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                {...other}
            >
                {children}
            </InternalLink>
        )
    }
    return (
        <Anchor href={to} {...other}>
            {children}
        </Anchor>
    )
}

export default Link

const Anchor = styled.a`
    position: relative;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:link,
    &:visited {
        color: ${Theme.text.link};
    }

    &:hover,
    &:active {
        color: ${Theme.text.hover};
    }
`

const InternalLink = styled(GatsbyLink)`
    position: relative;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:link,
    &:visited {
        color: ${Theme.text.link};
    }

    &:hover,
    &:active {
        color: ${Theme.text.hover};
    }
`

export const Button = styled.button`
    margin: var(--space-s) 0;
    padding: var(--space-3xs) var(--space-xs);
    border: 1px solid ${Theme.text.link};
    border-radius: var(--space-3xs);
    text-decoration: none;
    background: ${Theme.text.inverse};
    color: ${Theme.text.link};

    ${(props) =>
        props.primary &&
        css`
            background: ${Theme.text.link};
            color: ${Theme.text.inverse};
        `}

    &:hover {
        cursor: pointer;
    }
`

const InternalButton = styled.button`
    margin: var(--space-s) 0;
    padding: var(--space-3xs) var(--space-xs);
    border: 1px solid ${Theme.text.link};
    border-radius: var(--space-3xs);
    text-decoration: none;
    background: ${Theme.text.inverse};
    color: ${Theme.text.link};

    ${(props) =>
        props.primary &&
        css`
            background: ${Theme.text.link};
            color: ${Theme.text.inverse};
        `}

    &:hover {
        cursor: pointer;
    }
`
